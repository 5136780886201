import React from "react";
import maintenanceLogo from "../images/maintenance/maintenance-icon.png";
import ContactForm from "../tools/ContactForm";
// icons
import mailIcon from "../images/maintenance/mail.svg";
import locationIcon from "../images/maintenance/location.svg";
import phoneIcon from "../images/maintenance/telephone.svg";
import githubIcon from "../images/maintenance/github.svg";
import linkedinIcon from "../images/maintenance/linkedin.svg";
import fiverIcon from "../images/maintenance/fiverr.svg";
import "../styles/Maintenance.css";

function Maintenance() {
  return (
    <section className="main-container">
      <div className="main-logo-wrapper">
        <img
          src={maintenanceLogo}
          className="main-logo"
          alt="a hand holding a wrench that is passing through a gear."
        />
      </div>
      <div className="main-text-container">
        <p className="main-text">
          Hello, welcome to my portfolio. The website is currently under going
          some changes but please feel free to reach out to me using the
          resources below.
        </p>
      </div>
      <div className="main-contact">
        <h4 className="main-contact-subtitle">CONTACT</h4>
        <h2 className="main-contact-title">Get in Touch</h2>
        <div className="contact-section-wrapper">
          <div className="contact-details-wrapper">
            <h4 className="contact-detail-title">Get in Touch</h4>
            <p className="contact-text">
              Please feel free to contact me about new projects or
              opportunities.
            </p>
            <div className="contact-info-wrapper">
              <div className="contact-info">
                <img src={locationIcon} alt="a location pin" /> Rotterdam,
                Netherlands
              </div>
              <div className="contact-info">
                <img src={mailIcon} alt="an envelope" />{" "}
                canselenferidun@gmail.com
              </div>
              <div className="contact-info">
                <img src={phoneIcon} alt="a minimalistic old phone handle" />{" "}
                +31 648599645
              </div>
            </div>
            <div className="contact-social-icon">
              <a
                href="https://github.com/Feridun12"
                target="_blank"
                rel="noreferrer"
                className="social-icon-wrapper"
              >
                <img
                  src={githubIcon}
                  alt="github logo"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/feriduncanselen/"
                target="_blank"
                rel="noreferrer"
                className="social-icon-wrapper"
              >
                <img
                  src={linkedinIcon}
                  alt="linkedIn logo"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.fiverr.com/s/38RDZo9"
                target="_blank"
                rel="noreferrer"
                className="social-icon-wrapper"
              >
                <img
                  src={fiverIcon}
                  alt="fiverr logo"
                  className="social-icon"
                />
              </a>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default Maintenance;
