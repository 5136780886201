import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/Maintenance.css";

function ContactForm() {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qaig6p9",
        "template_k3ffr3n",
        formRef.current,
        "EwDY51O29PTZwJTqB"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form-wrapper">
      <form className="contact-form" ref={formRef} onSubmit={handleSubmit}>
        <h4 className="contact-detail-title">Reach out to me! </h4>
        <label htmlFor="user name">Name</label>
        <input
          className="contact-input"
          type="text"
          id="user name"
          placeholder="Your Name"
          name="user_name"
          required={true}
        />
        <label htmlFor="user subject">Subject</label>
        <input
          className="contact-input"
          type="text"
          id="user subject"
          placeholder="Subject"
          name="user_subject"
          required={true}
        />
        <label htmlFor="user email">Email</label>
        <input
          className="contact-input"
          type="email"
          id="user email"
          placeholder="Your Email"
          name="user_email"
          required={true}
        />
        <label htmlFor="contact-message-area">Description</label>
        <textarea
          id="contact-message-area"
          rows="5"
          placeholder="Your Message"
          name="message"
        />
        <div className="contact-button-wrapper">
          <button
            type="submit"
            className={`contact-button ${done ? "submitted" : ""}`}
            disabled={done}
          >
            <span className="button-text">Submit</span>
            <span className={`checkmark ${done ? "show" : ""}`}>✓</span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
